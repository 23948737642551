// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";
.autoWidth {
    width: auto;
}

.input-wrapper > input  {
    display: block;
    width: 100%;
    height: calc(2.0625rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-wrapper > input:focus {
    color: #5c6873;
    background-color: #fff;
    border-color: #8ad4ee;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}
.input-wrapper > span {
    font-weight: bold !important;
}
.select-wrapper > span {
    font-weight: bold !important;
}
.select-wrapper > select  {
    display: block;
    width: 100%;
    height: calc(2.0625rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.select-wrapper > select:focus {
    color: #5c6873;
    background-color: #fff;
    border-color: #8ad4ee;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}

.navbar-brand > img {
    height: 50px;
    width: 100%;
}

.Toastify__toast--success{
    background: #a2dcf1!important;
}

.Toastify__toast-container--top-center{
    top: 40%!important;
}

.custom-file-label::after {
    display: none !important;
}