body {
    background-color: #e4e4e4;
	font-family: Open Sans;
    margin-top: 10%;
    margin-bottom: 50px;
}

#question {
    background-color: #0094da;
    color: white;
    padding: 10px;
    text-align: center;
}
/* margins */
.m-l-5{
    margin-left: 5px;
}
.m-l-18{
    margin-left: 18px;
}
.m-l-10{
    margin-left: 10px;
}
.m-l-12{
    margin-left: 12px;
}
.nm-l-10{
    margin-left: -10px;
}
.m-l-25{
    margin-left: 25px;
}
.m-r-10{
    margin-right: 10px;
}
.m-r-15{
    margin-right: 15px;
}
.m-r-20{
    margin-right: 20px;
}
.m-r-30{
    margin-right: 30px;
}
.m-r-100{
    margin-right: 100px;
}
.m-l-42{
    margin-left: 42px;
}
.nm-l-5{
    margin-left: -5px;
}
.m-l-20{
    margin-left: 20px;
}
.m-l-23{
    margin-left: 23px;
}
.m-l-45{
    margin-left: 45px;
}
.m-l-24{
    margin-left: 24px;
}
.m-l-64{
    margin-left: 64px;
}
.m-t-5{
    margin-top: 5px;
}
.m-t-20{
    margin-top: 20px;
}
.m-t-10{
    margin-top:10px !important;
}
.m-t-25{
    margin-top:25px ;
}
.m-t-30{
    margin-top: 30px;
}
.m-t-16{
    margin-top: 16px;
}
.m-b-0{
    margin-bottom: 0px !important;
}
.m-b-50{
    margin-bottom: 50px;
}
.m-b-16{
    margin-bottom: 16px;
}
.m-b-20{
    margin-bottom: 20px;
}
.m-b-25{
    margin-bottom: 25px;
}
.m-b-30{
    margin-bottom: 30px;
}
/* margins css end */

/* custom padding css start */
.p-t-10{
    padding-top: 10px;
}
.p-t-12{
    padding-top: 12px;
}
.p-t-15{
    padding-top: 15px;
}
.p-t-20{
    padding-top: 20px;
}
.p-t-25{
    padding-top: 25px;
}
.p-b-15{
    padding-bottom: 15px;
}
.p-b-25{
    padding-bottom: 25px;
}
.p-b-30{
    padding-bottom: 30px;
}
.p-b-50{
    padding-bottom: 50px;
}
.p-l-20{
    padding-left: 20px;
}
.p-l-30{
    padding-left: 30px;
}
.border-radius20{
    border-radius: 20px !important;
}
#question h4{
    background-color: #fff;
    color: #0b0e21;
    display:inline-block;
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 600;
}

#question p{
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 2px;
    padding-left: 50px;
    padding-right: 50px;
}

#answers ul{
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
}

#answers li {
    background-color: #fff;
    /* border: 2px solid black; */
    min-height: 20px;
    width: 48%;
    display: flex;
    margin-bottom: 15px;
    transition: color .3s ease, border-color .3s ease, transform .3s ease;
    cursor: pointer;
}

#answers li span {
    /* background-color: #0084da; */
    /* color: #0084da; */
    color:"black";
    font-size: 30px;
    flex: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .3s ease;
}

#answers li p {
    color: #0b0e21;
    font-size: 16px;
    font-weight: 600;
    flex: calc(100% - 75px);
    margin: auto 20px;
    transition: color .3s ease;
}

#answers li:after{
    display: block;
}

/* #answers li:hover {
    transform: scale(1.03);
} */

#answers li.right {
    border-color: #1ea55b;
    color: #1ea55b;
}

#answers li.right span {
    background-color: #1ea55b;
}

#answers li.right p {
    color: #1ea55b;
}

#answers li.wrong {
    border-color: #dc0a0a;
    color: #dc0a0a;
}

#answers li.wrong span {
    background-color: #dc0a0a;
}

#answers li.wrong p {
    color: #dc0a0a;
}

#submit {
    text-align: center;
    margin: 20px 0;
}

.fancy-btn {
    border: 2px solid #0b0e21;
    border-radius: 0;
    background-color: #0b0e21;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    padding: 14px 75px;
    margin: 0 auto;
    text-transform: uppercase;
    transition: color .2s ease, background-color .2s ease;
}

.fancy-btn:hover {
    background-color: #fff;
    color: #0b0e21;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.popup h1 {
    background-color: #0094da;
    color: white;
    border-bottom: 1px solid #ccc;
    padding: 20px;
    margin-top: 0;
}

.popup p {
    font-size: 18px;
    letter-spacing: 1px;
    margin: 20px 10% 0;
}

.popup .fancy-btn{
    margin: 20px auto;
}

footer {
    background-color: #0b0e21;
    color: white;
    letter-spacing: 1px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    text-align: center;
}

footer p {
    margin: 0;
}

footer small {
    margin-bottom: 10px;
}

footer span {
    color: crimson;
}
/* student overview css */
.main-overview-container{
    position: relative;
    height: auto;
    background-color: rgb(249, 249, 249);
    margin-top: 28px;
    margin-left: 25px;
    margin-bottom: 50px;

}
.overall-text-head{
    color: #000000;
    font-family: Poppins;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    font-weight: 600;
}
.right-arrow-alignment{
    margin-left: 10px;
}
.result-btn-style{
    background-color:  #36A817;
    color:  #FFFFFF;
    border-radius: 5px;
    border:none;
    width: 120px;
    height: 38px;
}
.performace-card-box{
    background-color: #FFFFFF;
    border-radius: 20px !important; 
    box-shadow: 0 10px 20px 0 #DFDFDF;
    width: 98%;
    height: 160px;
    /* border-style: solid!important; */
    border-color: tomato!important;
    margin-top: 15px;
}
.test-card-style{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #c8ced3;
    border-radius: 10px;
    height: 102px;
    width: 105%;
    margin-right: 6px;
    margin-left: -15px;
    box-shadow: 0 10px 20px 0 #DFDFDF;
    margin-bottom: 15px;
}
.yourAvgMargin{
    margin-left: 42px;
}
.scoreSubleft{
    margin-left: -5px;
}
.percentLeftS{
    margin-left: 23px;

}
.submitTestectionPopup{
    background-color: #FFFFFF;
    border-radius: 20px;
    width: 430px;
    overflow: hidden;
    height: 200px;
    left: 50%;
    top: 50%;
    z-index: 2000;
    transform: translate(-50%, -50%);
    position: absolute;
}
.sumbitMessageText{
    color: #38B75B;
    font-family: Poppins;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
}
.outer-submit-message-div{
    padding: 85px;
}
.classAvg{
    margin-left: 45px;
}
.scoretext{
    margin-left: -5px;
}
.percentageSecond{
    margin-left: 24px;
}
.topperAvg{
    margin-left: 60px;
}
.time-minutes-txt{
    width: 10%;
}
.question-icon-style{
    width: 10%;
}
.test-average-score {
    color: #848484;
    font-family: Poppins;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    display: block;
    font-weight: 600;
    margin-top: 10px;
  }
  .test-average-score-sub {
    color: #848484;
    font-family: Poppins;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    display: block;
    font-weight: 600;
  }
.scheduled-test-outerdiv{
    margin-top: 48px;
}
.graph-line-yellow{
    border: 2px solid #EAD10A;
    text-align: center;
    display: block;
    margin-top: 30px;
    width: 25px;
    margin-left: 73px;
}
.graph-line-red{
    border: 2px solid #E96767;
    text-align: center;
    display: block;
    margin-top: 30px;
    width: 25px;
    margin-left: 73px;
}
.graph-line-purple{
    border: 2px solid #7B5DC6;
    text-align: center;
    display: block;
    margin-top: 30px;
    width: 25px;
    margin-left: 73px;
}
.inner-div-scheduledTest{
    margin-bottom: 18px;
    color: #000000;
    font-family: Poppins;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    font-weight: 600;
}
.percentage-value-score{
    color: #626262;
    font-family: Poppins;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    display: block;
    margin-top: 15px;
}
.attempted-test-outerdiv{
    margin-top: 48px;
}
.inner-div-attemptedTest{
    margin-bottom: 18px;
    color: #000000;
    font-family: Poppins;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    font-weight: 600;
}
.unattempted-test-outerdiv{
    margin-top: 48px; 
}
.inner-div-unattemptedTest{
    margin-bottom: 18px;
    color: #000000;
    font-family: Poppins;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    font-weight: 600; 
}
.half-mask-card-purple{
    background-image: linear-gradient(180deg, #7867AF 0%, #5C4C90 99.94%);
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 50%;
  
}
.half-mask-card2{
    border-radius: 0 0 10px 10px;
    width: 100%;
    height: 50%;
    padding-top: 10px;
}
.half-mask-card-pink{
    background-image: linear-gradient(180deg, #F38282 0%, #FA6868 100%);
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 50%;
}
.half-mask-card-grey{
    background-image: linear-gradient(180deg, #DBDBDB 0%, #C3C3C3 100%);
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 50%;
}
.neet-text-head{
    color: #FFFFFF;
    font-family: Poppins-SemiBold;
    font-size: 14px;
    line-height: 8px;
    text-align: left;
    font-weight: 600;
    display: block;
    margin-top: 17px;
    margin-left: 20px;
}
.test-timing-details{
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 10px;
    line-height: 12px;
    opacity: 0.5;
    text-align: left;
    font-weight: 400;
    margin-left: 20px;
}
.test-timing-details-blc{
    color: #000000;
    font-family: Poppins;
    font-size: 10px;
    line-height: 12px;
    opacity: 0.5;
    text-align: left;
    font-weight: 400;
    margin-left: 20px;
}
.text-head-clr{
    color: #000000;
    font-family: Poppins;
    font-size: 14px;
    line-height: 8px;
    text-align: left;
    font-weight: 600;
    display: block;
    margin-top: 17px;
    margin-left: 20px;
}
.show-all-btn{
    padding-left: 90px !important;
    text-decoration: underline;
    color: #928f8f;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    font-weight: 600;
}
.clock-icon-style{
    margin-left: 10px;
    margin-right: 20px;
    margin-top: 8px;
}
.clock-icon-style-ques{
    margin-left: 10px;
    margin-right: 20px;
    margin-top: 8px;
}
.test-btn-style{
    border: none;
    background-color: #F2F0F1;
    border-radius: 8px;
    width: 34%;
    height: 30px;
}
.testRank-oval{
    background-color: #D2E8CA;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    position: relative;
    margin-top: 32px;

}
.oval2{
    transform: translate(13%, 13%);
    border-radius: 100%;
    box-shadow: 0 0 5px 0 #88CE76;
    width: 80px;
    height: 80px;
    background-color: #6AD64D;
}
.testRank-number{
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 30px;
    line-height: 36px;
    text-align: left;
    transform: translate(33%, -49%);
    width: 100px;
    height: 100px;
    font-weight: 800;
}
.rank-title-txt{
    color: #626262;
    font-family: Poppins;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    display: block;
}
.rank-title-txt1{
    color: #626262;
    font-family: Poppins;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    display: block;
}
.rank-subtitle-txt{
    color: #C6C4C7;
    font-family: Poppins;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
}
.rankSecondDiv{
    margin-top: 45px;
}
.bg-clr-white{
    background-color: #ffffff;
}
.instructionsNotes-container-test{
    background-color: #F2F0F1 !important;
    border-radius: 20px;
    width: 100%;
    height: auto;
    padding: 36px 10px 65px 15px;
}
.takeTest-btn-div{
    text-align: center;
    margin: 42px 10px 100px 10px;
    padding-bottom: 78px;
}
.takeTest-btn{
    background-color: #36A817 !important;
    border-radius: 10px !important;
    width: 300px;
    height: 60px;
    margin: 0;
    position: relative;
    top: 50%;
    color: #FFFFFF !important;
    font-family: sans-serif;
    font-size: 20px !important;
    border: none !important;
    font-weight: 600 !important; 
}
.notes-head-txt{
    color: #626262;
    font-family: Poppins;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
}
.container-head-notes{
    text-align: center;
}
.exam-detail-card-outer1{
    width:33.33%;
    padding-left: 13px;
    padding-right: 7px;
}
.exam-detail-card-outer2{
    width:33.33%;
    padding-left: 13px;
    padding-right: 7px;
}
.exam-detail-card-outer3{
    width:33.33%;
    padding-left: 13px;
    padding-right: 7px;
}
.exam-detail-card{
    background-color: #F2F0F1 !important;
    border-radius: 10px !important;
    width: 100%;
    height: 80px;
}
.subtitle-instruction-txt{
    color: #626262;
    font-family: Poppins;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    display: inline;
    margin-right: 70px;
}
.qtyNumber-txt{
    color: #68579D;
    font-family: Poppins;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
}
.clock-icon-style-instru{
    margin-left: 20px;
    margin-right: 30px;
    margin-top: 30px;
}
.timingTestQuestion{
    margin-top: 5px;
    margin-right: 30px;
}
.question-icon-style-intru{
    margin-left: 20px;
    margin-right: 30px;
    margin-top: 30px;
}
.refresh-fa-icon{
    margin-left: 20px;
    margin-right: 30px;
    margin-top: 30px;
}
.container-head-test-course{
    color: #000000;
    font-family: Poppins;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    font-weight: 600;
    padding-top: 36px;
    padding-bottom: 10px
}
.instruction-head-text-style{
    color: #626262;
    font-family: Poppins;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 52px;
}
.list-items-instruction-card{
    color: #000000;
    font-family: WorkSans-m;
    text-align: left;
    font-weight: 400;
    font-size: 17px;
}

/* test Questions css */

.column-head-main-offsetone{
    flex: 0 0 93.66667%;
    max-width: 93.66667%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    background-color: #F2F0F1;
    height: 60px;
    padding-top: 15px;
    display: flex;
}

.column-head-main-offsetTwo{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    background-color: #F2F0F1;
    height: 70px;
    padding-top: 15px;
    margin-top: 55px;
    margin-bottom: 100px;
}
.daily-test-course-head-left{
    color: #000000;
    font-family: sans-serif;
    font-size: 18px;
    line-height: 29px;
    text-align: left;
    font-weight: 400;
    /* padding-top: 15px; */
    padding-bottom: 10px;
    display: inline;
    margin-left: 10px;
    
}
.questions-detail-container{
    border: 1px solid #DFDBDC;
    border-radius: 10px;
    width: 710px;
    height: auto;
    padding: 40px 40px;
}
.jumptoquestions-questions{
    border: 1px solid #DFDBDC;
    border-radius: 10px;
    width: 350px;
    height: auto;
}
.submitTestPopup{
    position: absolute;
    top: 33%;
    left: 35%;
    right: 36%;
    bottom: 40%;
    border: 1px solid gray;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling:touch ;
    border-radius: 5px;
    outline: none;
    padding: 20px;
    z-index:2000;
    width: 30%;
}
.submitTestPopupSuccess{
    position: absolute;
    top: 33%;
    left: 35%;
    right: 36%;
    bottom: 40%;
    border: 1px solid gray;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling:touch ;
    border-radius: 5px;
    outline: none;
    padding: 20px;
    z-index:2000;
    width: 30%;
}
.assignedTestPopupOverPlay{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.52);
}
.testSectiontext{
    text-align: center;
    color: #e90a32;
    margin-bottom: 20px;
}
.testSectiontextSub{
    text-align: center;
    font-family: sans-serif;
}
.question-number-head{
    display: inline;
    color: #000000;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    font-weight: 600;
}
.testMarks-obtained{
    background-color: #F2F0F1;
    border-radius: 13px;
    width: 100px !important;
    height: 27px;
    text-align: center;
    padding-top: 4px;
    font-family: sans-serif;
    font-size: 14px;
    color: #626262;
}
.testMarks-negative{
    background-color: #FFD9D9;
    border-radius: 13px;
    width: 100px !important;
    height: 27px;
    text-align: center;
    font-family: sans-serif;
    font-size: 14px;
    padding-top: 4px;
    color: #FA6868;
}
.typeofTest{
    background-color: #F2F0F1;
    border-radius: 13px;
    width: auto;
    height: 27px;
    text-align: center;
    padding-top: 4px;
    color: #626262;
    font-family: sans-serif;
    font-size: 14px;
    border: none;
    padding-right: 10px;
    padding-left: 10px;
}
.question-number-head-outerdiv{
    width:18%;
    margin-top: 4px;
    padding-left: 85px;
}
.question-number-head-outerdiv2{
    width: 21%;
    margin-top: 4px;
    padding-left: 75px;
}
.testMarks-obtained-outerdiv{
    width:11%;
}
.testMarks-negative-outerdiv{
    width:11%;
}
.typeofTest-outerdiv{
    width: 25%;
}
.typeofTest-outerdivone{
    width: auto;
}
.sectionsTest-outerdivr{
    display: flex;
    text-align: center;
    width: 35%;
    align-items: center;
}
.numberofsections-outerdiv{
    width: auto;
}
.green-sections-btn{
    color: #ffffff !important;
    background-color: #3DAF00 !important;
    border-color: #3DAF00 !important;
    font-weight: 600 !important;
}
.green-sections-btnActive{
    color: #ffffff !important;
    background-color: #3DAF00 !important;
    border-color: #3DAF00 !important;
    font-weight: 600 !important;
    border: 2px solid #6c757d !important;
    box-shadow: 0 0 0 0.2rem rgba(175, 181, 186, 0.5);
}

.test-questions-title-text{
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 600;
}
.option-A-test-outer{
    background-color: #D2E8CA;
    height: auto;
    border-radius: 8px;
    display: flex;
}
.option-correct-answer-outer{
    background-color: #D2E8CA;
    height: auto;
    border-radius: 8px;
    display: flex;
    align-items: center;
}
.emptyOptionsection{
    height: 44px;
    display: flex;
}
.option-right-test-outer{
    background-color: #258a31;
    height: 40px;
    border-radius: 8px;
}
.option-A-test-inner{
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 4px;
    margin-left: 5px;
    border-radius: 5px;
    border: none;
    background-color: #36A817;
    color: white;
}

.answer-names-txt{
    margin-left: 25px;
    font-weight: 600;
    font-size: 18px;
    font-family: sans-serif;
}
.jumpToQuestionsHead{
    background-color: #DFDBDC;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    padding-top: 10px;
}
.jumpToQuestionsHead-results{
    background-color: #DFDBDC;
    border-radius: 16px;
    height: 40px;
    text-align: center;
    padding-top: 8px;
    width: 98%;
}
.jumpTo-question-titlecss{
    color: #000000;
    font-family: sans-serif;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
   
}
.unselected-answer-btn{
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 4px;
    margin-left: 5px;
    border-radius: 5px;
    border: none;
    background-color: #DFDBDC;
    color: black;
}
.TestStatus-dot-font{
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 8px;
    margin-right: 12px;
}
.clr-white{
    color: white;
}
.clr-black{
    color: black;
}
.clr-787878{
    color: #787878;
}
.clr-626262{
    color: #626262;
}
.clr-454545{
    color: #454545;
}
.clr-3C3C3C{
    color: #3C3C3C;
}
.clr-FFFFFF{
    color: #FFFFFF;;
}
.bg-clr-0700C6{
    background-color: #0700C6;
}
.bg-clr-green{
    background-color: #36A817;
}
.bg-clr-orange{
    background-color: #FF8200;
}
.bg-clr-dark-grey{
    background-color: #626262 !important;
    color: #fff !important;
    /* border: 2px solid #6c757d !important; */
    box-shadow: 0 0 0 0.2rem rgba(175, 181, 186, 0.5);
}
.bg-clg-g248c49{
    background-color: #248c49 !important;
}
.bg-clr-light-grey{
    background-color: #D8D8D8;
}
.bg-clr-E1E5ED{
    background-color: #E1E5ED;
}
.bg-clr-B0B6C0{
    background-color: #B0B6C0;
}
.bg-clr-D2E8CA{
    background-color: #D2E8CA;
}
.result-correct-questionNo{
    background-color: #36A817 !important;
}

.TestStatus-text-font{
    color: #626262;
    font-family: sans-serif;
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    display: inline;
}

.selected{
    color: #fff !important;
    border-color: #a5aeb7 !important;
    box-shadow: 0 0 0 0.2rem rgba(175, 181, 186, 0.5);
}
.wrong-answer-btn{
    background-color: #cc3f29 !important;
}
.marked-dot-style{
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 8px;
    margin-right: 12px;
}
.questionNumber-btn{
    border-radius: 10px;
    border: none;
    padding: 7px 12px 7px 12px;
    color: white;
    margin-left: 7px;
    margin-right: 9px;
}
.questionNumber-btn2{
    border-radius: 10px;
    border: none;
    padding: 7px 8px 7px 8px;
    color: white;
    margin-left: 7px;
    margin-right: 9px;
}
.eleven-btn-padding{
    padding: 7px 9px 7px 9px !important;
}
.time-questionsCompleted-div{
    background-color: #F2F0F1;
    border-radius: 10px;
    width: 350px;
    height: 54px;
    text-align: center;
    padding-top: 9px;

}
/* .next-prev-submitOuter{
    bottom: -78 !important;
} */
.time-remaining-questionsAttempt{
    color: #000000;
    font-family: sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 19px;
    font-weight: 600; 
}
.timeRemain-completed-txt{
    color: #626262;
    font-family: sans-serif;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
}
.previousNext-button-outerdiv{
    background-color: #FFFFFF;
    border-radius: 10px;
    width: 110px;
    height: 44px;
    text-align: center;
    padding-top: 13px;
}

.previousNext-button-text{
    color: #000000;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 17px;
    display: inline;
    font-weight: 600;
    margin-top: -6px;
}
.submit-button-outerdiv{
    background-color: #36A817;
    border-radius: 10px;
    width: 180px;
    height: 40px;
    text-align: center;
    padding-top: 10px;
    margin-top: 4px;
    margin-left: 200px;
}
.pauseTest-outerBtn{
    margin-top: 6px;
    margin-left: 25px;
}
.submit-button-text{
    color: #FFFFFF;
    font-family: sans-serif;
    font-size: 16px;
    line-height: 19px;
    display: inline;
    font-weight: 600;
}
.chech-faIcon-pads{
    padding-right: 25px;
    margin-left: -25px;
    color: white;
}
.answered-questionsGraphPercent{
    background-color: #C6C4C7;
    border-radius: 5px;
    opacity: 0.300000011920929;
    width: 100%;
    height: 10px;
    margin-top: 10px;
    /* margin-left: 20px; */
}
.percentage-value-marks{
    color: #000000;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 34px;
    margin-top: 8px;
    margin-left: 20px;
    font-weight: 600;
}
.answered-questionsGraphPercent-green{
    background-color: #36A817;
    border-radius: 3px;
    width: 30%;
    height: 6px;
    margin-top: 2px;
    margin-left: 0px;
}
.iconRightAngle{
    padding-left: 3px;
    padding-right: 5px;
    font-size: 20px;
}
.iconleftAngle{
    padding-left: 30px;
    font-size: 20px;
}

/* student Test Results and Rank page Css */
.student-current-rank-card{
    background-color: #FFFFFF;
    border-radius: 20px !important;
    width: 100%;
    height: 140px;
}
.testRank-oval-results{
    background-color: #D2E8CA;
    border-radius: 100%;
    width: 110px;
    height: 110px;
    position: relative;
}
.resultsOval2{
    border-radius: 100%;
    box-shadow: 0 0 5px 0 #88CE76;
    width: 90px;
    height: 90px;
    transform: translate(12%, 11%);
    background-color: #37b316;
}
.testRank-number-results{
    color: #FFFFFF;
    font-family: sans-serif;
    font-size: 30px;
    line-height: 36px;
    text-align: left;
    transform: translate(48%, -54%);
    width: 100px;
    height: 100px;
    font-weight: 800;
}
.studentRank-oval-alignment{
    padding-left: 50px;
}
.currentRank-numberStudent-outer{
    margin-top: 35px;
}
.current-rank-title-green{
    color: #36A817;
    font-family: sans-serif;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 5px;
}
.numberOf-studentTitle{
    color: #626262;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
}
.seeAllResults-head{
    color: #6D6D6D;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    text-align: left;
    display: inline;
}
.neetTestResults-head{
    color: #000000;
    font-family: sans-serif;
    font-size: 20px;
    line-height: 24px;
    margin-right: 15px;
    font-weight: 600;
}
.inner-header-container {
    padding-top: 25px;
    padding-bottom: 25px;
}
.neetTestResults-head-outer-div{
    text-align: right;
}
.testRank-questions-container{
    width: 100%;
    height: auto;
    padding: 40px 40px;
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 #DFDFDF;
}
.resultsRankJumptoquestions{
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 #DFDFDF;
    width: 90%;
    height: auto;
    margin-left: 40px;
    padding-top: 8px;
    padding-left: 5px;
}
.student-leaderBordCard{
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 #DFDFDF;
    width: 100%;
    height: 325px;
    display: flex;
    flex-wrap: wrap;
}
.leaderBordCard-inner{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 0%;
}
.student-overallScoreCard{
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 #DFDFDF;
    width: 100%;
    height: 325px;
}
.student-performanceGraphCard{
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 #DFDFDF;
    width: 100%;
    height: 500px;
}
.student-performanceGraphCardFirst{
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 #DFDFDF;
    width: 100%;
    height: 500px;
}
.performaceOutDivPadRespon{
    padding-bottom: 25px;
}
.paddingstautsRes{
    padding-top: 25px;
}
.leaderBoard-titleText{
    color: #626262;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    font-weight: 600;
}
.scoreBoard-titleText{
    color: #626262;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    font-weight: 600;
}
.RankpurpleOval{
    background-color: #DCDBFF;
    border-radius: 100%;
    opacity: 0.8;
    width: 50px;
    height: 50px;
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
}
.RankpurpleOval-inner{
    border-radius: 100%;
    box-shadow: 0 0 5px 0 #3732D0;
    width: 40px;
    height: 40px;
    transform: translate(12%, 12%);
    background-color: #3732D0;
}
.insideOval-rankNumber{
    color: #FFFFFF;
    font-family: sans-serif;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    font-weight: 600;
    transform: translate(1%, 55%);
}
.studentName-withScrore{
    margin-top: 18px;
    margin-left:10px;
}
.RankstudentNameOnLeaderboard{
    font-family: sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    margin-bottom: 0px;
    font-weight: 600;
}
.half-div{
    width: 100%;
    padding-left: 15px;
    padding-right: 10px;
}
.testRank-oval-blue{
    background-color: #DCDBFF;
    border-radius: 100%;
    width: 110px;
    height: 110px;
}
.resultsOvalblue2{
    border-radius: 100%;
    width: 90px;
    height: 90px;
    position: relative;
    background-color: #3732D0;
    transform: translate(11%, 11%);
}
.testRankNumber-inBlueOval{
    color: #FFFFFF;
    font-family: sans-serif;
    font-size: 30px;
    line-height: 36px;
    text-align: left;
    width: 85px;
    height: 100px;
    font-weight: 800;
}
.transform3r{
    transform: translate(39%, -54%) !important;
}
.transform2r{
    transform: translate(45%, -54%) !important;
}
.transform1r{
    transform: translate(55%, -54%) !important;
}
.blueOval-alignment-pad{
    padding-left: 50px;
    padding-top: 27px;
}
.Your-score-title-blue{
    font-family: sans-serif;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 5px;
    color: #3732D0;
    font-weight: 600;
}
.improvedScoreBtn{
    background-color: #D2E8CA;
    border-radius: 11px;
    width: 115px;
    height: 40px;
    margin-left: 200px;
    margin-top: 37px;
    text-align: center;
}
.improvedScoreBtntxt{
    color: #36A817;
    font-size: 14px;
    font-weight: 400;
    font-family: sans-serif;
    margin-top: 11px;
}
.scoreStatusContainer{
    border: 1px solid #DFDBDC;
    border-radius: 10px;
    width: 92%;
    height: 90px;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 50px;
}
.testStatusheading{
    color: #000000;
    font-family: sans-serif;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    font-weight: 600;
    display: contents;
}
.statusObatinerRankMarks{
    color: #000000;
    font-family: sans-serif;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    font-weight: 600;
}
.displayflexContinous
{
    display: flex;
    flex-wrap: wrap;
    margin-left: 16px;
    margin-top: 10px;
    margin-right: 15px;
}
.cursorPointer
{
    cursor: pointer;
}
.borderRadius5px
{
    border-radius: 5px;
}
.border1pxsSolidBlack
{
    border: 1px solid #c8c8c8; 
}
.paddingAttempt
{
    border-radius: 11px;
    padding: 8px 15px;
    text-align: center;
    min-width: 100px;
    color: #36A817;
    font-size: 14px;
    font-weight: 400;
    font-family: sans-serif;
}
.sectioninfobtn{
    background-color: #F2F0F1 !important;
    border-radius: 13px !important;
    width: auto !important;
    height: 27px;
    text-align: center !important;
    padding-top: 4px !important;
    font-family: sans-serif;
    font-size: 14px !important;
    color: #626262 !important;
}
.sectioninfobtnActive{
    background-color: #F2F0F1 !important;
    border-radius: 13px !important;
    width: auto !important;
    height: 27px;
    text-align: center !important;
    padding-top: 4px !important;
    font-family: sans-serif;
    font-size: 14px !important;
    color: #626262 !important;
    border: 2px solid #6c757d !important;
    box-shadow: 0 0 0 0.2rem rgba(175, 181, 186, 0.5);
}
.backgrondcolorLightGreen
{
    background-color:#37b316;
}
.backgroundColorUnattempt
{
    background-color: #f0f3f5;
}
.checkFa-green{
    padding-right: 10px;
    margin-left: 20px;
    color: #36A817;
}
.unCheckfa-redIcon{
    padding-right: 10px;
    margin-left: 20px;
    color: #FA6868;
}
.exclamationIcon-clr{
    padding-right: 10px;
    margin-left: 20px;
    color: #626262;
}
.widthOfAccuracyIcon{
    width: 14%;
    margin-left: 4px;
    margin-right: 10px;
    color: #1a0dd8;
}
.straight-line-yellow{
    border: 2px solid #EAD10A;
    text-align: center;
    display: block;
    margin-top: 30px;
    width: 40px;
    margin-left: 73px;
}
.straight-line-purple{
    text-align: center;
    display: block;
    margin-top: 30px;
    width: 40px;
    margin-left: 73px;
    border: 2px solid #7B5DC6;
}
.timeTakenByTitleAligment{
    padding-top: 22px;
    margin-left: -40px;
}
.timeTakenByTitle{
    color: #626262;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    font-weight: 600;
}
.myPerformanceContainer{
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    position: relative;
    width: 100%;
    display: contents;
}
.timeTakenCardYoursToppers{
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 #DFDFDF;
    width: 100%;
    height: 80px;
}
.questionsAttepmtCardYoursToppers{
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 #DFDFDF;
    width: 100%;
    height: 131px;
}
.total-timeTakenHeader{
    color: #626262;
    font-family: sans-serif;
    font-size: 13px;
    line-height: 16px;
    padding-top: 18px;
    padding-left: 40px;
    margin-bottom: 5PX;
    font-weight: 600;
}
.total-timeTakenSubHeader{
    color: #68579D;
    font-family: sans-serif;
    font-size: 20px;
    line-height: 24px;
    padding-left: 40px;
    font-weight: 600;
}
.verticalLineAtCenter{
    border: 1px solid #C6C4C7;
    margin-left: 30px;
    margin-top: 15px;
}
.question-number-fonttext{
    color: #000000;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    font-weight: 600;
}
.questionsabc-correct-marks{
    color: #3DAF00;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    font-weight: 600;
}
.Youare-Ans-styleT{
    color: #848484;
    font-family: sans-serif;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
    font-weight: 600;
    margin-right: 20px;
}
.horizontalLine-mid-divider{
    border: 1px solid #DFDBDC;
}
.outer-questions-eclipse{
    border: 1px solid #DFDBDC;
    border-radius: 15px;
    width: 260px;
    height: 30px;
    margin-left: 50px;
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
}
.outer-questions-eclipse-first{
    border: 1px solid #DFDBDC;
    border-radius: 15px;
    width: auto;
    height: 30px;
    margin-left: 50px;
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
}
.inner-questions-eclipse-grey{
    background-color: #F2F0F1;
    border-radius: 15px;
    width: 80px;
    height: 28px;
    text-align: center;
}
.inner-questions-eclipse-grey-first{
    background-color: #F2F0F1;
    border-radius: 15px;
    width: auto;
    height: 28px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}
.question-difficulty-text{
    padding: 4px 35px 0px 15px;
    color: #848484;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 600;
}
.question-difficulty-text-first{
    padding: 4px 20px 0px 15px;
    color: #848484;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 600;
    margin-right: auto;
}
.inner-eclipsefont{
    color: #000000;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding-top: 6px;
}
.questionNumber-btn-results{
    border-radius: 10px;
    border: none;
    padding: 8px 13px 8px 13px;
    color: white;
    margin-left: 8px;
    margin-right: 10px;
}
.questionNumber-btn2-results{
    border-radius: 10px;
    border: none;
    padding: 8px 9px 8px 9px;
    color: white;
    margin-left: 8px;
    margin-right: 10px;
}
.outer-nextprevious-alignment{
    margin-left: 30px;
    padding-top: 25px;
}
.inner-nextprevious-alignment1{
    padding-left: 125px;
}
.inner-nextprevious-alignment2{
    padding-left: 80px;
}
.previousNext-button-outerdiv-results{
    background-color: #FFFFFF;
    border-radius: 10px;
    width: 110px;
    height: 44px;
    text-align: center;
    padding-top: 13px;
}
.padding-top-nextPrivious{
    padding-top: 40px;
    margin-top: 60px;
}
.resultsRankNextPrivious{
    width: 70%;
    height: auto;
    margin-left: 113px;
    padding-top: 0px;
    padding-left: 5px;
    margin-top: -75px;
}
.nextPrivious-outerDiv{
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 #DFDFDF;
    width: 110px;
    height: 44px;
    padding-top: 10px;
}
.priviousNextButton-fontSize{
    color: #000000;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    text-align: center;
    display: contents;
}
.iconRightAngle-results{
    padding-left: 15px;
    padding-right: 15px;
    font-size: 20px;
}
.iconleftAngle-results{
    padding-left: 30px;
    font-size: 20px;
}
.testRank-questions-seperationsLine{
    border: 1px solid #C6C4C7;
}
.solution-pink-btn{
    background-color: #FA6868;
    border-radius: 10px;
    width: 160px;
    height: 36px;
    margin-bottom: 30px;
}
.solution-pink-btn-inside{
    color: #FFFFFF;
    font-family: sans-serif;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    text-align: center;
    padding-top: 8px;
}
.descriptionTextSolution{
    color: #626262;
    font-family: sans-serif;
    font-size: 16px;
    line-height: 19px;
    width: 600px;
    font-weight: 600;
    text-align: left;
}
.option-B-test-outer-results{
    background-color: #FFE4E4;
    border-radius: 8px;
    height: auto;
    display: flex;
    align-items: center;
}
.onSelectAnswerStatusCorrect{
    color: #36A817;
    font-family: sans-serif;
    font-size: 12px;
    line-height: 45px;
    display: inline;
    margin-left: auto;
    margin-right: 20px;
    font-weight: 600;
}
.onSelectYourAnswerStatus{
    color: #626262;
    font-family: sans-serif;
    font-size: 12px;
    line-height: 42px;
    display: inline;
    margin-left: auto;
    margin-right: 20px;
    font-weight: 600;
}

/* overall performace rank page css */
.examRankOverviewOfAllStudents-outer-div{
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 #DFDFDF;
    width: 100%;
    height: 650px;
    padding-top: 15px;
}
.examRankOverall-greyheader-inner{
    background-color: #DFDBDC;
    border-radius: 10px;
    width: 98%;
    height: 40px;
    margin-bottom: 22px;
    margin-left: 13px !important;
}
.examRankScoreHead-withArrow{
    color: #6D6D6D;
    font-family: sans-serif;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    display: inline;
}
.neet-test-score-points{
    font-family: sans-serif;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    text-align: left;
}
.overAllRank-pagination-btn{
    border-radius: 100%;
    width: 24px;
    height: 24px;
    margin-left: 15px;
    text-align: center;
    padding-top: 2px;
}
.paddingLelfPagination{
    padding-left: 180px;
}
.pagination-first-btn{
    color: #061754;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 16px;
    opacity: 0.2000000029802322;
    text-align: left;
    margin-top: 4px;
}
.pagination-last-btn{
    color: #000000;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    margin-top: 4px;
}
.straight-line-lightBlue{
    border: 2px solid #1FCCAC;
    text-align: center;
    display: block;
    margin-top: 30px;
    width: 40px;
    margin-left: 73px;
}
.gradeConcept-image{
    width: 65%;
    border-radius: 21px;
    margin-left: 232px;
}
@media (max-width: 768px) {
    #question p {
        padding-left: 10px;
        padding-right: 10px;
    }
    
    #answers li {
        width: 100%;
    }
    
    #submit button {
        width: 100%;
    }
}
@media (max-width: 480px) {
    
    footer {
        padding: 5px;
    }
    
    footer small {
        display: none;
    }
}
.shadow_box_wrapper{
    margin-top: 25px;
    border-radius: 5px;
    background-color: #ffffff;
}
.exam-grid{
    padding-bottom: 25px;
    margin-bottom: 15px;
}
.card-header{
    background:transparent !important;
}
.link__style_with_arrow{
    margin-bottom: 0;
    position:relative;
}
.exam-grid .exam-wrapper {
    border-radius: 4px !important;
    background-color: #ffffff;
    border:1px solid #e2e6ee;
    padding:20px 20px 20px 50px;
    position: relative;
    margin: 15px;
}
.common__text_exam{
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d2e2c;
    margin-bottom: 0;
}
.subheading__style{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9daab5;
}
.alert-blue{
    background-color: rgba(44, 165, 248, 0.1);
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2ca5f8;
    padding: 0px 5px;
    border-radius: 2px;
}
.solid__button_w_arrow{
    border-radius: 2px;
    box-shadow: 0 8px 14px -6px rgba(0, 0, 0, 0.18);
    background-color: #2ca5f8;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    padding: 8px 30px 8px 16px;
    position: relative;
    transition: all .2s ease;
    border: 1px solid #2ca5f8;
}
.exam-grid .card-header {
    padding: 18px 20px;
    border-bottom: 1px solid #e2edf9;
}
.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}


.instructions__panel {
    width: 78%;
    margin: auto;
    padding: 0 60px 30px 60px;
    margin-bottom: 100px;
  }
  
  .shadow__box_wrapper {
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    border: none;
  }
  
  .exam-grid .card-body {
    padding-bottom: 0 !important;
    padding-top: 24px;
  }
  .instructions__panel h1 {
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.04;
    letter-spacing: normal;
    color: #9daab5;
    margin: 25px 0;
  }
  .instructions__panel .instructions__duration {
    padding: 0;
    margin: 0;
    border-radius: 8px;
    border: 1px solid #dae5ed;
    margin-top: 25px;
  }
  .instructions__panel .instructions__duration li {
    display: inline-block;
    width: 32%;
    padding: 14px 24px;
  }
  .instructions__panel .instructions__duration li span {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7a7d8685;
  }
  .instructions__panel .instructions__duration li p {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  
  .instructions__panel .instructions__section {
    margin-top: 40px;
  }
  
  .instructions__panel .instructions__section h2 {
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    color: #3f4046;
    margin-bottom: 15px;
  }
  
  .instructions__panel .instructions__section ul {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    padding: 0;
    justify-content: space-between;
  }
  .instructions__panel .instructions__section ul li {
    width: 49%;
    border: 1px solid #dbe1ea;
    display: inline;
    margin-bottom: 15px;
    border-radius: 6px;
    padding: 12px 20px;
  }
  
  .instructions__panel .instructions__section ul li p {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.81;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 0;
  }
  
  .instructions__panel .instructions__section ul li p span {
    opacity: 0.6;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.07;
    letter-spacing: normal;
    color: #000000;
    float: right;
  }
  .answerPannel{
    cursor: pointer;

  }

.answeroption img{
height:30px !important;
width: auto !important;
}
.response{
    height: 30em;
    border: 1px solid black;
    overflow-y:auto;
    overflow-x:hidden;
}
.attempted{
    color:#4dbd74 !important; 
}
.notAttempted{
    color:#f86c6b !important;
}
.reviewed{
    color:#ffc107 !important;
}

.rowContent{
    margin-left: 15px !important;
    
}
.sticky-details-row {
    position: sticky !important;
    top: 56px;
    background-color: white;
    z-index: 2 !important;
    padding: 5px 0px;
    WIDTH: 100%;
    MARGIN-LEFT: 0;
    -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.1);
}
.select-all-question {
    display: flex;
}
.checkbox-wrapper {
    width: 10%;
    float: left;
}

.advanceFilter{
    color:#2ca5f8;
    cursor: pointer !important;
}
.selectedQuestionInfo{
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}
.fetchQuestion{
    margin-left: 1em;
}
.buttoncolor{
    background-color: #c18c8c !important;
    border-color:#c18c8c !important;
}
.testSection{
    text-align: center 
}
.testsectionBody{
    margin-top: 15%;
    margin-bottom: 13%;
}
.testTimeSchedules{
    font-size: 15px;
    font-weight: 400;
}
.questionCheckbox{
    zoom:1.5;
}
.selectAllQuestions{
    zoom:1.5;
    margin-top: 0.1rem!important;
}
.testsection{
    margin-top: 25%;
    margin-bottom: 25%;
}
.borderBottetCreatesection {
    border-bottom: 1px solid grey;
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 20px;
}
.showAll{
    margin-left:1rem !important;
}
.editAssignedTestPopup{
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 650px;
    position: absolute;
    border: 1px solid grey;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 5px;
    outline: none;
    padding: 20px;
    z-index: 2000;
}
.createTestectionPopup{
    position: absolute;
    top: 10rem;
    left: 29rem;
    right: 17rem;
    bottom: 7rem;
    border: 1px solid grey;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 5px;
    outline: none;
    padding: 20px;
    z-index: 2000;
    width: 430px;
    overflow: hidden;
    height: 400px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    margin-left: 51px;
}


/* Responsive css start */
@media (min-width: 320px) and (max-width: 1023.9px) { 
    .result-btn-style{
        width: 90px !important;
      }
      .main-overview-container{
        margin-left: 0px !important;
      }
      .performace-card-box{
        width: 100%;
      }
      .graph-line-yellow {
        border: 2px solid #EAD10A !important;
        text-align: center !important;
        display: block !important;
        margin-top: 17px !important;
        width: 20px !important;
        margin-left: 10px !important;
      }
      .yourAvgMargin{
        margin-left: 0px !important;
      }
      .test-average-score{
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        display: block;
        font-weight: 600;
      }
      .test-average-score-sub{
        font-size: 12px;
      }
      .percentage-value-score{
        font-size: 14px;
        line-height: 15px;
      }
      .student-leaderBordCard{
        margin-left: 18px;
        width: 104%;
      }
      .leaderBoard-titleText {
        margin-left: 25px;
    }
    .student-overallScoreCard {
        width: 103%;
        margin-left: 4px;
        height: 285px;
    }
    .student-performanceGraphCard {
        width: 98%;
        margin-left: 20px;
        height: 510px;
    }
    .student-performanceGraphCardFirst{

    }
    .time-questionsCompleted-div{
        width: 330px;
    }
    .typeofTest-outerdivone{
        margin-top: 15px;
    }
    .next-prev-submitOuter{
        bottom: 10px !important;
    }
    .performaceOutDivPadRespon{
        padding-right: 0px !important;
    }
    .padding-left-correct{
        padding-left: 25px !important;
    }
    .padding-left-incorrect{
        padding-left: 10px !important;
    }
    .padding-left-unans{
        padding-left: 0px !important;
    }
    .padding-left-acc{
        padding-left: 5px !important;
    }
    .responsivTimecard{
        padding-right: 4px !important;
    }
    .responsivTimecardsec{
        padding-left: 4px !important;
    }
    .paddingstautsRes{
        padding-top: 15px;
    }
    .total-timeTakenHeader {
        font-size: 12px;
        padding-top: 10px;
        padding-left: 10px;
        margin-right: -41px;
    }
    .total-timeTakenSubHeader {
        font-size: 12px;
        padding-left: 10px;
    }
    .scoreBoard-titleText {
        margin-top: 20px;
        margin-left: 10px;
    }
    .blueOval-alignment-pad {
        padding-left: 30px;
        padding-top: 15px;
    }
    .transform2r {
        transform: translate(30%, -49%) !important;
    }
    .resultsOvalblue2 {
        width: 70px;
        height: 70px;
        transform: translate(7%, 7%);
    }
    .testRank-oval-blue {
        width: 80px;
        height: 80px;
        margin-right: 10px !important;
    }
    .testRankNumber-inBlueOval{
        font-size: 25px;
    }
    .improvedScoreBtn {
        width: 90px;
        height: 35px;
        margin-left: 25px;
        margin-top: 24px;
    }
    .improvedScoreBtntxt {
        font-size: 12px;
    }
    .Your-score-title-blue{
        font-size: 16px;
        margin-bottom: 0px;
    }
    .scoreStatusContainer{
        margin-right: 20px;
        margin-left: 15px;
        margin-top: 40px;
    }
    
      .graph-line-purple {
        border: 2px solid #7B5DC6;
        text-align: center !important;
        display: block !important;
        margin-top: 17px !important;
        width: 20px !important;
        margin-left: 10px !important;
      }
      .graph-line-red {
        border: 2px solid #E96767;
        text-align: center !important;
        display: block !important;
        margin-top: 17px !important;
        width: 20px !important;
        margin-left: 10px !important;
      }
      .paddingAttempt {
        padding: 4px 5px;
        min-width: 70px;
        font-size: 12px;
    }
    .neetTestResults-head{
        font-size: 13px;
        margin-right: -30px;
    }
      .classAvg{
        margin-left: 0px;
      }
      .percentage-value-score{
        margin-left: 0px;
      }
      .topperAvg {
        margin-left: 0px;
    }
    .testRank-oval {
        width: 70px;
        height: 70px;
    }
    .oval2 {
        transform: translate(8%, 8%);
        border-radius: 100%;
        width: 60px;
        height: 60px;
    }
    .testRank-number{
        font-size: 25px;
        transform: translate(22%, -44%);
    }
    .plminus{
        padding-left: 0px !important;
    }
    .prZero{
        padding-right: 0px !important;
    }
    .rankSecondDiv {
        margin-top: 30px;
    }
    .rank-title-txt{
        font-size: 16px;
    }
    .rank-title-txt1{
        font-size: 16px;
    }
    .rank-subtitle-txt{
        font-size: 14px;
    }
    .show-all-btn{
        padding-left: 0px !important;
    }
    .test-card-style{
        margin-bottom: 10px;
    }
    .plResposin{
        padding-left: 30px !important;
    }
    .respmarginnextprev{
        margin-top: -35px;
    }
    .list-items-instruction-card{
        font-size: 15px;
    }
    .instructionsNotes-container-test {
        padding: 36px 5px 65px 0px;
    }
    .clock-icon-style-instru {
        margin-left: 5px;
        margin-right: 10px;
        margin-top: 17px;
        display: inline-flex;
    }
    .subtitle-instruction-txt{
        margin-right: 0px;
    }
    .qtyNumber-txt{
        display: flex;
        flex-wrap: wrap;
        margin-left: 37px;
    }
    .question-icon-style-intru {
        margin-left: 5px;
        margin-right: 9px;
        margin-top: 16px;
    }
    .refresh-fa-icon {
        margin-left: 5px;
        margin-right: 10px;
        margin-top: 14px;
    }
    .daily-test-course-head-left{
        padding: 0px !important;
        font-size: 14px;
        line-height: 16px;
        padding-bottom: 0px;
        margin-left: 10px;
    }
    .clock-icon-style{
        margin-left: -5px;
        margin-right: 7px;
    }
    .clock-icon-style-ques{
        margin-left: -5px;
        margin-right: 7px;
        margin-top: 0px;
    }
    .fapadding{
        padding: 0px !important;
    }
    .timingTestQuestion{
        margin-top: 0px;
        margin-right: 0px;
    }
    .question-number-head-outerdiv {
        width: 20%;
        margin-top: 0px;
        padding-left: 2px;
    }
    .question-number-head-outerdiv2{
        width:18%;
        margin-top: 4px;
        padding-left: 0px;
    }
    .question-number-head {
        font-size: 12px;
        padding-right: 10px;
    }
    .testMarks-obtained-outerdiv {
        width: 20%;
    }
    .testMarks-obtained {
        width: 65px !important;
        font-size: 12px;
       
    }
    .testMarks-negative-outerdiv {
        width: 20%;
    }
    .testMarks-negative {
        width: 65px !important;
        font-size: 12px;
    }
    .typeofTest-outerdiv {
        width: 40%;
    }
    .typeofTest {
        width: auto !important;
        font-size: 10px;
        padding-top: 8px;
    }
    .questions-detail-container {
        border: 1px solid #DFDBDC;
        border-radius: 10px;
        width: 100%;
        height: 500px;
        padding: 10px 10px;
    }
    .test-questions-title-text {
        font-size: 14px;
    }
    .option-A-test-outer {
        height: 30px;
    }
    .option-A-test-inner {
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 2px;
    }
    .answer-names-txt {
        font-size: 14px;
    }
    .jumptoquestions-questions {
        width: 100%;
        margin-top: 10px;
  
    }
    .previousNext-button-outerdiv {
        width: 70px;
        height: 40px;
        padding-top: 10px;
    }
    .iconRightAngle {
        padding-right: 10px;
        font-size: 15px;
        margin-left: -3px;
    }
    .previousNext-button-text {
        font-size: 12px;
    }
    .iconleftAngle {
        padding-left: 20px;
        font-size: 15px;
    }
    .chech-faIcon-pads {
        padding-right: 10px;
        margin-left: -5px;
        color: white;
    }
    .submit-button-text {
        color: #FFFFFF;
        font-family: sans-serif;
        font-size: 12px;
        display: inline;
        font-weight: 400;
    }
    .chech-faIcon-pads {
        padding-right: 10px;
        margin-left: 0px;
        color: white;
        font-size: 12px;
    }
    .submit-button-outerdiv {
        width: 96px;
        height: 40px;
        padding-top: 10px;
        margin-top: 0px;
        margin-left: 10px;
    }
    .half-mask-card2{
        padding-left: 10px;
    }
    .outer-submit-message-div {
        padding: 50px;
    }
    .submitTestectionPopup{
        width: 90%;
        height: 20%
    }
    .studentRank-oval-alignment {
        padding-left: 25px;
    }
    .testRank-oval-results {
        width: 77px;
        height: 77px; 
        margin-right: 15px !important; 
    }
    .resultsOval2 {
        width: 65px;
        height: 65px;
        transform: translate(9%, 9%);
    }
    .testRank-number-results {
        transform: translate(24%, -47%);
    }
    .currentRank-numberStudent-outer {
        margin-top: 18px;
    }
    .current-rank-title-green {
        font-size: 16px;
    }
    .numberOf-studentTitle {
        font-size: 12px;
    }
    .gradeConcept-image {
        width: 40%;
        margin-left: 183px;
        margin-top: -81px;
        height: 80px;
    }
    .straight-line-purple {
        width: 30px;
        margin-left: 12px;
    }
    .timeTakenByTitleAligment {
        padding-top: 22px;
        margin-left: 0px;
    }
    .straight-line-yellow {
        width: 30px;
        margin-left: 12px;
    }
    .straight-line-lightBlue {
        width: 30px;
        margin-left: 12px;
    }
    .timeTakenByTitle {
        font-size: 12px;
    }
    .straight-line-lightBlue {
        width: 30px;
        margin-left: 12px;
    }
    .straight-line-yellow {
        width: 30px;
        margin-left: 12px;
    }
    .timeTakenByTitleAligment {
        padding-top: 22px;
        margin-left: 0px;
    }
}
