.input-wrapper > input{
    font-size: 15px !important;
    border-radius: 3px !important;
    border: 1px solid #696d6f54 !important;
}

input[type="checkbox"]{
  display: inline!important;
  width: auto!important;
  height: auto!important;
  float: left;
  margin: 4px;
}

.imageBlock{
  height: 720px;
  overflow-y: auto;
}

span.error{
 color:red !important;
}

.nav-dropdown-items>.nav-item{
    margin-left: 9% !important;
}

input:disabled {
  background:hsl(0,0%,95%)!important;
}

.cursor{
  cursor: pointer;
}
.p-3 {
  padding: 1rem !important;
}
.text-center {
  text-align: center !important;
}
.jaypore-credit-previous{
  height: 15px;
  opacity: 0.2;
  /* color: #A4E0FB; */
  color: black;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  margin-left: 16px;
  margin-right: 16px;
}
/* .jaypore-credit-previous{
  font-size: 13px;
}
.jaypore-credit-next{
  font-size: 13px;
} */
.jaypore-credit-next{
  height: 15px;
  font-size: 14px;
  /* color: #06A9F4; */
  color: black;
  cursor: pointer !important;
  font-weight: 600;
  margin-right: 16px;
  margin-left: 16px;

}
.nprecb{
  /* background-color: #BB4226; */
  background-color: #0700C6 !important;
  border: 1px solid #0700C6;
    color: white;
    /* padding: 0 17px; */
    margin: 0 4px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
}
/* .nprecb{
  padding:0px !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
} */

.nprecb1{
  /* color: #C65D41; */
  background-color: #E1E5ED;
  /* padding: 0 17px; */
  margin: 0 4px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid #E1E5ED
  /* background-color: #f5e3d8; */
}

/* .nprecb1{
  padding: 0px !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
} */

.jq-toast-single {
    padding: 15px;
    font-family: "Libre Franklin", sans-serif;
    background-color: #35b8e0;
    font-size: 13px;
    line-height: 22px; }
    .jq-toast-single h2 {
      font-family: "Libre Franklin", sans-serif; }
    .jq-toast-single a {
      font-size: 14px; }
      .jq-toast-single a:hover {
        color: #fff; }
  
  .jq-has-icon {
    padding: 10px 10px 10px 50px; }
  
  .jq-icon-info {
    background-color: #35b8e0;
    color: #fff;
    border-color: #35b8e0; }
  
  .jq-icon-success {
    background-color: #31ce77;
    color: #fff;
    border-color: #31ce77; }
  
  .jq-icon-warning {
    background-color: #fbcc5c;
    color: #fff;
    border-color: #fbcc5c; }
  
  .jq-icon-error {
    background-color: #f34943;
    color: #fff;
    border-color: #f34943; }
  
  .close-jq-toast-single {
    position: absolute;
    top: -12px;
    right: -12px;
    font-size: 20px;
    cursor: pointer;
    height: 32px;
    width: 32px;
    background-color: #525663;
    border-radius: 50%;
    text-align: center;
    line-height: 32px; }
  
  .jq-toast-loader {
    height: 3px;
    top: 0;
    border-radius: 0; }

    .bg-black{
      background-color: #525663;
    }

    .bg-blue {
      background-color: aqua;
    }

    .css-26l3qy-menu{
      margin-top: 0 !important;
    }

    .label-margin{
      margin-bottom: 0;
    }

    .mt-25{
      margin-top: 25%;
    }

    .w-10{
      width: 10%;
    }

    .text-red{
      color: red;
    }
    .text20{
      font-size: 20px;
    }
    .ReactTable .rt-th, .ReactTable .rt-td{
      white-space: unset !important;
      text-align: justify !important;
    }
    .modal-dialog{
      min-width: 75% !important;
    }
    .detailImage{
      height: 1.1rem;
    }
    span.hide:not(.match-in-children) {
      display: block !important;
  }
  .react-dropdown-tree-select .dropdown .dropdown-content{
    z-index: 99 !important;
  }
  .nav-tabs .nav-link.active{
    background-color: white !important;
    border-color: white !important;
    border-bottom: 5px solid #3e0202 !important;
    
  }
.width-vediojs{
  width: 250px;
}
  .nav-tabs{
    
    border-bottom-color: white !important;
  }
  .banner-overlay {
    position: absolute;
    width: 95%;
    height: 60px;
    top: 0px;
    left: 0;
}
.headerLine
{
  background-color: #3e0202 !important;
}
.card{
  border: 0px !important;
}

.iframeVideo
{
  position: relative; 
  top: 0;
  left: 0;
  width: 95%;
  height: 450px
}
.p-l-10px
{
    padding-left: 10px;
}

.textn-input:focus{
    border: solid 2px #2684FF !important;;
    box-sizing: 0 0 0 1px #2684FF !important;;
}
.width100Percent
{
  width: 100%;
}
.listStyleType{
  list-style-type:none

}
#colorHighlighted
{
  color:whitesmoke !important;
}
.open-button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 50px;
  right: 20px;
  width: 263px;
}

/* The popup chat - hidden by default */
.chat-popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 36px;
  border: 1px solid #20a8d8;
  z-index: 9;
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
  background-color: #4CAF50;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom:10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover, .open-button:hover {
  opacity: 1;
}
.pf {
   margin: 0px auto !important; 
   box-shadow: none !important; 
  border-collapse: separate;
  position: absolute !important;
  top: -50px;
}
#page-container {
  background-color: #ffffff !important;
  background-image: none !important;
  transition: left 500ms;
  overflow: hidden !important;
}
.h4
{
  min-height: 80px !important;
}
.video{

position: relative;

 height: 0
}
.iframeChat                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
{
  position: relative; 
  top: 0;
  left: 0;
  width: 100%;
  height: 387px
}




@media only screen and (max-width:1177px){
  .search_icon_allignment {
    position: relative !important;
    left : -2% !important;
  }
}


@media only screen and (max-width: 768px) {
  .app-header {
    height : 110px !important
  }
  
  .app-body {
    position: relative;
    top: 55px !important;
  }
  .live_2nd_page_outer_row{
    padding : 0px !important;
  }
  
  .live_2nd_page_card_padding {
    padding : 0px !important;
  }

  .chat-popup {
    display: none;
    position: fixed;
    bottom: 0;
    right:0px;
    top:100px;
    border: 1px solid #20a8d8;
    z-index: 9;
  }
  .iframeVideo
{
  position: absolute; 
  top: 0;
  left: 0;
  width: 98%;
  height: 235px
}
.iframeChat
{
  position: absolute; 
  top: 0;
  left: 0;
  width: 100%;
  height: 260px
}
.video{

  position: relative;
  padding-top: 2px;
   
  }
}

@media only screen and (max-width: 400px) {
  .app-header {
    height : 140px !important
  }
  
  .app-body {
    position: relative;
    top: 85px !important;
  }

  /* html:not([dir="rtl"]) .sidebar1 {
    margin-left: -300px !important;
  }
  html:not([dir="rtl"]) .sidebar-lg-show .sidebar1, html:not([dir="rtl"]) .sidebar-show .sidebar1 {
    margin-left: 0px !important;
  } */
}

.rectangle {
  background-color: #F9F9F9;
  width: 100%;
  height: 945px;
}

.learn {
  color: #000000;
  font-family: Poppins;
  font-size: 24px;
  line-height: 29px;
  text-align: left;
  position: relative;
  left: 2.59%;
  right: 3.19%;
  top: 2.22%;
}

.what-do-you-want-to {
  color: #000000;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 18px;
  opacity: 0.5;
  text-align: left;
  position: relative;
  left: 5.39%;
  font-weight: 600;
}
.rounded-10px {
  border-radius: 10px !important;
}
.neet-jee-cet {
  color: #000000;
  font-family: Poppins;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
}

.mask_blue {
  background-image: linear-gradient(90deg, #6F82F3 0%, #9CAAFF 100%);
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 #D8DEFF;
  width: 95%;
  height: 60px;
  position: relative;
  left: 2.5%;
  right: 2.5%;
}

.bitmap {
  width: 30px;
  height: 30px;
}

.ncert {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 26px;
  line-height: 31px;
  text-align: left;
  position: relative;
  left: 3%;
  top: 25%;
}
.subject-logo-card{
  cursor: pointer;
}
.mask_green {
  background-image: linear-gradient(270deg, #1FCCAC 0.06%, #2BB198 100%);
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 #C3E9E2;
  width: 95%;
  height: 60px;
  position: relative;
  left: 2.5%;
  right: 2.5%;
}

.mask_yellow {
  background-image: linear-gradient(270deg, #EAD10A 0%, #FFC100 100%);
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 #E7D59C;
  width: 95%;
  height: 60px;
  position: relative;
  left: 2.5%;
  right: 2.5%;
}

.user_name {
  color: #000000;
  font-family: Poppins;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
}

.notification {
  display: inline-block;
  position: relative;
  padding: 6px;
  background: #FFFFFF;
  border-radius: 8px;
  font-size: 1.1em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.search_box{
  border: none;
  color: #C6C4C7;
  font-family: Poppins;
  font-size: 20px;
  line-height: 19px;
  text-align: left;
  left:2%;
  padding: 8px;
  width: 80%;
}


.grade {
  color: #000000;
  font-family: Poppins;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  float: right;
  width: 100%;
  /* left: 35%; */
}

.search_icon {
  color:#C6C4C7;
  font-size: 23px !important;
  font-family: Poppins;
  left:1%;
}

.subject {
  color: #000000;
  font-family: Poppins;
  font-size: 22px;
  line-height: 30px;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 0px !important;
}

.chapters {
  color: #7160A7;
  font-family: Poppins;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
}

.all_chapter {
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 #DFDFDF;
  width: 95%;
  position: relative;
  left: 2.5%;
  right: 2.5%;
  /* height: 1370px; */
}

.numeric {
  background-color: #F2F0F1;
  border-radius: 10px;
  padding: 10px;
  color: #7160A7 !important;
  /* width: 40px;
  height: 40px; */
}

.chapter_name {
  color: #000000;
  font-family: Poppins;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
}

.duration {
  color: #7160A7;
  font-family: Poppins;
  font-size: 16px !important;
  line-height: 19px;
  text-align: left !important;
}
#mathcolor{
  color: #38B75B !important;
}
#physicscolor{
  color: #5C4C90 !important;
}
#chemistrycolor{
  color: #FFC100 !important;
}
#biocolor{
  color: #FA6868 !important;
}
#math-bg-color{
  background-color: #38B75B !important;
}
#physics-bg-color{
  background-color: #5C4C90 !important;
  color: white !important;
}
#chemistry-bg-color{
  background-color: #FFC100 !important;
}
#bio-bg-color{
  background-color: #FA6868 !important;
}
.mathcolor{
  color: #38B75B !important;
}
.physicscolor{
  color: #5C4C90 !important;
}
.chemistrycolor{
  color: #FFC100 !important;
}
.biocolor{
  color: #FA6868 !important;
}
.question {
  color: #7160A7;
  font-family: Poppins;
  font-size: 16px !important;
  line-height: 19px;
  text-align: left !important;
}

.no_of_videos {
  color: #7160A7 !important;
  font-family: Poppins;
  font-size: 16px !important;
  line-height: 19px;
  text-align: left !important;
}

.line {
  border: 1px solid #F2F0F1;
}
/* QR code css */
.superAdmin-header{
  text-align: right;
  padding: 15px;
  border-bottom: solid;
}
.border-line-right{
  border-right: solid;
  height: 700px;
  text-align: center;
}
.head-createQRcode{
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
}
.text-align-center{
  text-align: center;
}
.generateQRbtn{
  background-color: #43b6d7 !important;
  color: white !important;
  font-weight: 600 !important;
  margin-left: 25px;
  margin-top: 20px;
  border-radius: 5px;
  border: none;
  height: 35px;
}
.otp-box-signup{
  width: 50px;
  margin-right: 10px;
}
.signUpbtn{
  width: 100%;
  height: 60px;
  font-size: 20px !important;
  /* font-weight: 600 !important; */
  color: #fff !important;
  margin: 20px auto;
  background-color: #6f42c1 !important;
  border-color: #6f42c1 !important;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,.15);
  box-shadow: 0 0 10px 2px rgba(0,0,0,.15);
  background: linear-gradient(90deg,#7c32ff 0%,#c738d8 100%);
}
.facebookbtn{
  width: 333px;
  height: 60px;
  font-size: 20px !important;
  color: #fff !important;
  margin: 10px auto;
  background-color: #39579a !important;
  border-color: #39579a !important;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,.15);
  box-shadow: 0 0 10px 2px rgba(0,0,0,.15);
}
.googlebtn{
  width: 333px;
  height: 60px;
  font-size: 20px !important;
  color: #fff !important;
  margin: 10px auto;
  background-color: #dc4e41 !important;
  border-color: #dc4e41 !important;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,.15);
  box-shadow: 0 0 10px 2px rgba(0,0,0,.15);
}
.continueWith{
  font-size: 14px;
  font-weight: 600;
  color: #9b9b9b;
  margin-left: 111px;
}
.rightArrowCss{
  font-size: 22px !important;
  padding-top: 17px;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  color: #7c32ff;
  text-align: center;
}

.rightArrowCss:hover{
  color: #fff;
}
.signUpnextarrow{
  background: #fff;
  color: #7c32ff;
  border: 1px solid #7c32ff;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  position: relative;
  margin-left: 140px;
  margin-top: 35px;
}

.signUpnextarrow:hover {
  /* background-color: #346b34; */
  background: #7c32ff;
  border: 1px solid #7c32ff;

}
.otp-Upnextarrow{
    color: #7c32ff;
    border: 1px solid #7c32ff;
    background: #fff;
    border-radius: 100%;
    width: 60px;
    height: 60px;
    position: relative;
    margin-left: 145px;
    margin-top: 0px;
    margin-bottom: 15px;
}
.otp-Upnextarrow:hover{
  background: #7c32ff;
}
.otp-rightArrowCss:hover{
  color: #fff;
}
.otp-container {
  margin-left: 4px !important;
}
.zc-auth-otp-change-number{
  text-align: left;
  color : #26a0d0  !important;
  font-size: 14px;
  cursor: pointer;
  margin-left: 25px;
  font-weight: 600;
}
.otp-rightArrowCss{
  font-size: 22px !important;
  text-align: center;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  padding-top: 17px;
  color:  #7c32ff ;
}
.resend-otp{
  width: 95%;
  /* margin-left: 10px; */
  margin-top: 10px;
  margin-bottom: 20px;
  padding-right: 5px;
  text-align: right;
  font-size: 14px;
  opacity: .89;
  color: #26a0d0 !important;
  cursor: pointer;
}
.timer-resend-otp{
  width: 95%;
  /* margin-left: 10px; */
  margin-top: 10px;
  margin-bottom: 20px;
  padding-right: 5px;
  text-align: right;
  font-size: 14px;
  opacity: .89;
  color: #4a4a4a;
}
.zc-auth-otp-back{
  text-align: left;
  color: #26a0d0  !important;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 40px;
  margin-top: 15px;
}
.otp-sent-message{
  margin-bottom: 30px;
  font-size: 16px;
}
.otp-container-outer{
  text-align: center;
}
.otp-Phone-Png{
  width: 35%;
  margin-top: 10px;
  margin-bottom: 20px;
}
.updated_message{
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #43b6d7;
}
.questionIdLable{
  font-weight: 600;
}
.vedioURlLable{
  font-weight: 600;
}
.input-questionId{
  font-weight: 600;
}
.input-Urlpl{
  font-weight: 600;
}
.QRgenetaingList{
  font-weight: 600;
  padding-top: 200px;
}
.createQRText{
  font-weight: 600;
}
.closeIconouter{
  text-align: right;
  padding: 10px;
}
.question-code-span{
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
.download-btn-blue{
  background-color: #20a8d8;
  color: white;
  font-weight: 600;
  width: 170px;
  border-radius: 5px;
  border: none;
  height: 40px;
}
.qrIconSize{
  font-size: 12rem !important;
}
.autoLogoutPopup{
  background-color: #FFFFFF;
  width: 90%;
  overflow: hidden;
  height: 175px;
  left: 50%;
  top: 30%;
  z-index: 2000;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 10px;
  border: 1px solid grey;
  background: #fff;
}
.securityAlertHead{
  display: inline;
  padding: 5px;
  font-size: 25px;
  font-weight: 600;
}
.shieldStyle{
  color: #ef960f;
  font-size: 24px !important;
  padding-right: 10px;
}
.securityAlertHeadMainDiv{
  padding-top: 25px;
  text-align: center;
  padding-bottom: 30px;
  border-bottom: solid 2px;
  border-bottom-color: #d8d8d8;
}
.logout-Message-Auto{
  padding-top: 25px;
  text-align: center;
}
.genarateQrPopup{
  background-color: #FFFFFF;
  width: 430px;
  overflow: hidden;
  height: 450px;
  left: 50%;
  top: 50%;
  z-index: 2000;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 10px;
  border: 1px solid grey;
  background: #fff;
}

.errorMessagePopup{
  background-color: #FFFFFF;
  width: 270px;
  overflow: hidden;
  height: 100px;
  left: 31%;
  top: 33%;
  z-index: 2000;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 10px;
  border: 1px solid grey;
  background: #fff;
}
.upadted-VedioUrl-Popup{
  background-color: #FFFFFF;
  width: 430px;
  overflow: hidden;
  height: 150px;
  left: 50%;
  top: 50%;
  z-index: 2000;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 10px;
  border: 1px solid grey;
  background: #fff;
}
.qrerror-message-text{
  font-size: 14px;
}
.errorMessage{
    color:#bb4225;
    font-family: 'WorkSans-r';
    font-size: 15px;
}
.desktop-tooltip .desktop-tooltiptext {
  visibility: hidden;
  color: black;
  text-align: center;
  padding: 5px 11px;
  margin-top: 15px;
  left: 90%;
  margin-left: -165px;
  position: absolute;
  z-index: 1;
  width: 320px;
  height: 150px;
  box-shadow: 0 0 4px 0 #81cded;
  border: solid 1px #e9c0a8;
  background-color: #ffffff;
  border: 1px solid red;
  border-radius: 4px;
}
.size20FaIconClose{
  font-size: 20px;
  color: #4e4c4c;
}
.desktop-tooltip {
  position: relative;
  border-bottom: 1px black;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}
.desktop-tooltip:hover .desktop-tooltiptext {
  visibility: visible;
  border: solid 1px #acd9e7;
}

/* .sidebar .sidebar-nav {
  width: 250px !important;
}

.sidebar .nav {
  width: 250px !important;
}

.app-header .navbar-brand {
  width: 210px !important;
} */

/* html:not([dir="rtl"]) .sidebar { */
  /* margin-left: -250px !important; */
/* } */

/* 
} */

.app-header .nav-item {
    position: relative;
    min-width: 100px;
    margin: 0;
    text-align: center;
}

.profile {
  width: 13rem;
}

.select_option{
  width: 100px !important;
}

.chapter_header {
  color: #000000;
  font-family: Poppins;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
}

.row_style{
  width: 90% !important;
  position: relative;
  left: 5%;
  right: 5%;
}

.topic_header {
  color: #7365A9;
  font-family: Poppins;
  font-size: 20px;
  line-height: 19px;
  text-align: left;
  padding: 10px;
}

.video_name {
  color: #000000;
  font-family: Poppins;
  font-size: 14px;
  line-height: 14px;
  width: 225px;
  text-align: left;
}

.view_video_header {
  color: #000000;
  font-family: Poppins;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  padding: 15px 5px;
}

.navlink_style{
  background-color: #F9F9F9 !important;
  color: #000000;
  font-family: Poppins !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: left !important;
}

.nav-tabs .nav-link.active {
  background-color: #F9F9F9 !important;
  border-bottom: 5px solid #68579D !important;
}

.navlink_width {
  width: 9rem;
}

.up-next {
  color: #FF0000;
  font-family: Poppins !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-align: left !important;
}

.partial-differential {
  color: #000000 !important;
  font-family: Poppins !important;
  font-size: 20px !important;
  line-height: 8px !important;
  text-align: left !important;
}

.upnext_text {
  color: #000000;
  font-family: Poppins;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
}

.nav-bordera {
  border-top-left-radius:20px;
  border-bottom-left-radius:20px;   
  /* width: 100%; */
    color: #000000;
    font-family: Poppins;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
  }
  
.nav-borderb {
  border-top-right-radius:20px;
  border-bottom-right-radius:20px;  
  /* width: 100%; */
  color: #000000;
  font-family: Poppins;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  }

  .active_custom {
    background-color: #68579D !important;
    color: white !important;
  }

  .bg_custom {
    background-color: white;
    width: 99%;
    position: relative;
    left: 1%;
    border-radius: 10px;
    color: #000000;
    font-family: Poppins;
    font-size: 17px;
    line-height: 15px;
    text-align: left;
    padding: 10px;
  }

  .bg_custom1 {
    background-color: #F9F9F9;
    padding: 1px;
  }

  .live{
    color: #000000;
    font-family: Poppins;
    font-size: 15px;
    line-height: 17px;
    text-align: left;
    font-weight: 500;
    padding-bottom: 10px;
  }

  .card_subject {
    color: #000000;
    font-family: Poppins;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0px !important;
  }

.arrow_design {
  font-size: 20px !important;
  padding: 5px 10px;
  background-color: #F9F9F9;
  border-radius: 5px;
}

.youtube_icon {
  padding:20px ;
  font-size: 20px !important;
}

.card_text {
  color: #000000;
    font-family: Poppins;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0px !important;
}

.secondary_card_text {
  color: #DDDCDE;
  font-family: Poppins;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
}

.subject_text {
    color: #000000;
    font-family: Poppins;
    font-size: 18px;
    line-height: 17px;
    text-align: left;
    padding: 10%;
}

.view-all {
  color: #000000;
  font-family: Poppins;
  font-size: 13px;
  line-height: 14px;
  text-align: left;
}

.small_card{
  background-color: #F7F6FF !important;
  border-radius: 20px;
}

.l-1-sets-relation {
  color: #0123D1;
  font-family: Poppins;
  font-size: 15px;
  line-height: 14px;
  text-align: left;
  padding: 2px;
}

.small_card_subject {
  color: #626262;
  font-family: Poppins;
  font-size: 15px;
  line-height: 14px;
  text-align: left;
  padding: 5px 2px 0px;
}

.card_padding {
  margin-bottom : 0.2rem !important;
}
.pause-test-header{
  border-bottom: solid 2px #c7d7da;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  background-color: #f3f4f5;
}
.pause-message-student{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  font-size: 15px;
  font-family: sans-serif;
}
.pause-card-outer{
  margin-top: 25px;
  padding-bottom: 20px;
  box-shadow: 0 10px 20px 0 #DFDFDF;
  border: 1px solid #DFDBDC !important;
}
.btn-pausedYesNo{
  color: white !important;
  background-color: #319fde !important;
  border-color: #319fde !important;
}

.live-session {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  padding: 10px;
}

.live-completed-ses {
  color: #000000;
  font-family: Poppins;
  font-size: 17px;
  text-align: left;
}

table {
  border-collapse: collapse;
  width: 100%;
}
.answeroption{
  width: auto !important;
}
.answeroption1{
  width: 100% !important;
}
.answeroptionResult{
  width: auto !important;
  /* margin-top: -18px; */
}

td, th {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #F2F0F1;
}

table th:first-child{
  border-top-left-radius:20px;
  border-bottom-left-radius:20px;
}

table th:last-child{
  border-top-right-radius:20px;
  border-bottom-right-radius:20px;
}


table td:first-child{
  border-top-left-radius:20px;
  border-bottom-left-radius:20px;
}

table td:last-child{
  border-top-right-radius:20px;
  border-bottom-right-radius:20px;
}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  padding: 5px 10px;
  background-color: #E1E5ED;
  border-radius: 50px;
  font-family: "TitilliumWeb";
  font-size: 13px;
}

.pagination a.active {
  background-color: #0700C6 !important;
  color:  #FFFFFF;
  border-radius: 50px;
  
}

.page-item.active .page-link {
  background-color: #0700C6 !important;
  color:  #FFFFFF;
  border-radius: 50px;
}

.page-item {
  padding : 0px 2px;
}

.live_2nd_page_outer_row{
  padding : 30px 30px 20px 30px;
}

.live_2nd_page_card_padding {
  padding : 25px;
}




/* .sidebar >.nav-item > .dashboard_nav  >li.active {
  background-color: #EDFAE9 !important;
}

.sidebar >.nav-item > .learn_nav  >li.active {
  background-color: #E7E6FF !important;
}

.sidebar .live_nav .active {
  background-color: #FFF7DD !important;
} */

.header_row_style {
  display: flex;
    flex-wrap: wrap;
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 100% !important;
}

.app-header {
  height : 85px !important;
}

.app-body {
  position: relative;
  top: 30px !important;
}

.app-header .navbar-toggler {
  min-width: 40px !important;
}

.icon_allignments {
  position : relative !important;
  top : 10px !important;
}
.r-ml-courseDropdown{
  margin-left: -75px;
}
.res-margin-right-search {
  margin-right: 60px;
}
.search_icon_allignment {
  position: relative !important;
  left : -5%;
}

.secondary_icon_allignments {
  position : relative !important;
  top : 17px !important; 
  border: 1px solid #DFDBDC;
  border-radius : 10px; 
  color: #000000;
  /* display": "inline-block"; */
  width: 100%;
  padding: 10px;
  cursor: pointer;
}
.mlearnAlignment{
  margin-left: -260px;
}

.secondary_nav_subject {
  color: #000000;
  font-family: Poppins;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  font-weight: 600;
  margin-left: -8px;
  margin-bottom: 5px !important;
  margin-top: 18px !important;
}

.secondary_nav_count_chapters {
  color: #7160A7;
  font-family: Poppins;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  margin-left: -1px;
}
.subjectnameNav{
  margin-top: 19px !important;
  margin-bottom: 5px !important;
}
.secondary_nav_head {
  background-color: #EDFAE9 !important;
}

.secondary_nav_num_color{
  color: #000000;
  font-family: Poppins;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.secondary_nav_rectangle {
  background-color: #F2F0F1;
  border-radius: 16px;
  padding: 15px;
} 
.anchor_bottom_padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.welcome-back {
  color: #000000;
  font-family: Poppins;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  font-weight: 600;
}

.green_bar_design{
  background-image: linear-gradient(180deg, #46CD6B 0%, #38B75B 100%);
  border-radius: 10px;
  width: 10px;
  height: 90px;
}

.card_text {
  color: #000000;
  font-family: Poppins;
  font-size: 14px;
  /* line-height: 17px; */
  text-align: left;
  font-weight: 600;
}

.card_counter {
  color: #000000;
  font-family: Poppins;
  font-size: 20px;
  /* line-height: 24px; */
  text-align: left;
  font-weight: 600;
}

.purple_bar_design {
  background-image: linear-gradient(180deg, #7867AF 0%, #5C4C90 99.94%);
  border-radius: 10px;
  width: 10px;
  height: 90px;
}

.yellow_bar_design {
  background-image: linear-gradient(180deg, #EAD10A 0%, #FFC100 100%);
  border-radius: 10px;
  width: 10px;
  height: 90px;
}

.red_bar_design {
  background-image: linear-gradient(180deg, #F38282 0%, #FA6868 100%);
  border-radius: 10px;
  width: 10px;
  height: 90px;
}

.card_header_text {
  color: #6964F9;
  font-family: Poppins;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  font-weight: 600;
}

.mathematics {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  font-weight: 600;
  position: relative;
  top: -140px;
  left: 12%;
}

.dashboard_card_secondary_text {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  position: relative;
  top: -117px;
  left : -30%;
}
.outer-container-signup{
  background-color: white;
}
.signUpfirstModal{
  background-color: #FFFFFF;
  width: 80%;
  overflow: hidden;
  height: 450px;
  left: 50%;
  top: 61%;
  z-index: 2000;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 10px !important;
  /* border: 1px solid grey !important; */
  background: #fff;
  margin-top: 240px;  
  box-shadow: 0 0 15px rgba(185,55,223,.45);
  /* box-shadow: 0 10px 20px 0 #dfdfdf; */
}
.signUpfirstModalzz{
  background-color: #FFFFFF;
  width: 80%;
  overflow: hidden;
  height: auto;
  left: 50%;
  top: 61%;
  z-index: 2000;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 10px !important;
  /* border: 1px solid grey !important; */
  background: #fff;
  margin-top: 130px;  
  box-shadow: 0 0 15px rgba(185,55,223,.45);
  /* box-shadow: 0 10px 20px 0 #dfdfdf; */
}
.dashboard_card_secondary_text1 {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  position: relative;
  top: -130px;
  left : 13%;
}

.recordPlayButton{
  margin-top: 5px;
  border: none;
  width: 70px;
  margin-left: 14px;
  border-radius: 10px;
  height: 30px;
  font-size: 12px;
  padding-left: 5px !important;
  padding-right: 5px !important;
  /* background-color: rgb(223, 219, 220); */
  background-color: white;
}
.physics {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  font-weight: 600;
  position: relative;
  top: -140px;
  left: 12%;
}

.chemistry {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  font-weight: 600;
  position: relative;
  top: -140px;
  left: 12%;
}

.biology {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  font-weight: 600;
  position: relative;
  top: -140px;
  left: 12%;
}

.dashboard_card_secondary_text2 {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  position: relative;
  top: -117px;
  left : -18%; 
}

.dashboard_card_secondary_text3 {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  position: relative;
  top: -117px;
  left : -25%; 
}

.performance-analysis {
  color: #6964F9;
  font-family: Poppins;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 5px;
}


.bottom_subject_name {
  color: #000000;
  font-family: TitilliumWeb;
  font-size: 17px;
  line-height: 17px;
  text-align: left;
}

.green_dot {
  height: 14px;
  width: 14px;
  background-color: #38B75B;
  border-radius: 50%;
  display: inline-block;
  padding-right: 10px;
  vertical-align: middle;
}

.timing {
  color: #000000;
  font-family: TitilliumWeb;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
}

.design {
  font-size: 16px;
  color: lightgray;
}

.red_dot {
  height: 14px;
  width: 14px;
  background-color: #FA6868;
  border-radius: 50%;
  display: inline-block;
  padding-right: 10px;
  vertical-align: middle;
}

.design1 {
  font-size: 14px;
  color: lightgray;
}

.badge_secondary_bg_color {
  background-color: #F2F0F1 !important;
}

.nav_font_size {
  font-size : 18px
}

.purple_dot {
    /* color: #68579D; */
    /* font-family: Poppins; */
    /* font-size: 14px;
    line-height: 17px; */
    /* text-align: left; */
    display: inline-block;

    height: 10px;
    width: 8px;
    background-color: #68579D;
    border-radius: 50%;
    /* display: inline-block; */
    padding-right: 10px;
    vertical-align: middle;
}
html:not([dir="rtl"]) .sidebar {
  margin-left: -300px ;
 }
 html:not([dir="rtl"]) .sidebar-show .sidebar, html:not([dir="rtl"]) .sidebar-show .sidebar {
  margin-left: 0px !important;
 }
/* Responsive css start */
@media (min-width: 320px) and (max-width: 1023.9px) { 
  .mlearnAlignment{
    margin-left: 0px;
  }
  .r-ml-courseDropdown{
    margin-left: 0px;
  }
  .res-log-icon{
    margin-top: 10px;
  }
  .res-margin-right-search {
    margin-right: 0px;
  }
  .mask_blue{
    width: 90%;
    left: 4.5%;
  }
  .ncert{
    font-size: 24px;
  }
  .head-createQRcode{
    padding-left: 0px;
  }
  .learn {
    color: #000000;
    font-family: Poppins;
    font-size: 24px;
    line-height: 29px;
    text-align: left;
    position: relative;
    left: 2.59%;
    right: 3.19%;
    top: 2.22%;
}

.numeric{
  background-color: #F2F0F1;
  border-radius: 10px;
  padding: 8px;
  margin-left: 10px;
}
.chapter_name {
  color: #000000;
  font-family: Poppins;
  font-size: 15px;
}
.responsive-numerics{
  margin-left: -12px;
}
.responsive-chapaterName{
  bottom: 10px;
  margin-left: -8px;
  left: 20px;
}
.duration{
  font-size: 12px !important;
  
}
.durations-responsive{
  margin-left: -40px !important;
}
.badge-width-mobile1{
  padding: 5px 35px !important;
}
.badge-width-mobile2{
  padding: 5px 45px !important;
}
.mob-res-left{
  margin-left: -30px !important;
}
.minus-margin-resmob{
  margin-left: -30px !important;
}
.alignment-left-res{
  margin-left: -10px;
}
.chater_head_font{
  font-size: 15px;
  margin-left: -20px;
}
.duration-width-mob1{
  float: left !important;
}
.duration-width-mob2{
  width: 100% !important;
  margin-left: -15px;
}
.showall-chaters{
  padding-left: 0px !important;
  /* padding-right: 0px !important; */
}
.showall-chaters{
  font-size: 16px;
}
.view_video_header{
  font-size: 16px;
}
.rewid{
  width: 340px !important;
  height: 195px !important;
}
#mobile-resp-nextVedio{
  left: 2.5% !important;
  width: 100% !important;
}
.bg_custom{
  width: 93%;
  margin-bottom: 15px;
}
.open-button{
  position: absolute;
  width: 25%;
}
.up-next{
  margin-top: 10px;
}
.mob-live-padleft{
  padding-left: 15px;
  margin-top: 10px;
}
.mobile-card-completed{
  width: 91%;
  margin-left: 14px;
}
.width-vediojs{
  width: 155px !important;
  height: 115px !important;
}
.view-all{
  padding-right: 10px;
}
.mobSession-livetab{
  font-size: 12px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.testSession-livetab{
  font-size: 12px;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.recordPlayButton{
  margin-top: 10px;
  border: none;
  width: 70px;
  margin-left: 14px;
  border-radius: 10px;
  height: 30px;
  font-size: 12px;
  padding-left: 5px !important;
  padding-right: 5px !important;
  background-color: white
}
.faArrow-mob{
  padding: 0px 10px 0px 10px !important;
}
.subDropdown{
  margin-top: 10px;
  margin-right: 10px;
}
.mobSubHeaderTop{
  margin-top: 10px;
  margin-bottom: 15px;
}
.live-completed-ses{
  font-size: 14px;
  text-align: center;
  padding-left: 10px !important;
}
.live_2nd_page_card_padding{
  margin-left: 10px;
  padding: 0px !important;
  width: 95%;
}
.percentLeftS{
  margin-left: 0px;
}

}